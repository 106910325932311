import React, { FC, useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { useSelector } from 'react-redux'
import useResizeObserver from 'use-resize-observer'
import { useOpenFin } from '../../app/openFinContext'
import {
  getSecurityOrderDataById,
  getSecurityStaticDataById
} from '../../store/securities/selectors'
import PopoutDepthOfMarket from '../DepthOfMarket/PopoutDepthOfMarket'
import { copyAppStyles } from './helpers'
import styles from './popouts.module.scss'

interface Props {
  showCloseAll: boolean
  securityId: number
  isAdmin: boolean
}

const popupElementId = 'depthPopupRoot'

export const DepthPopoutHost = (props: any) => {
  return <div id={popupElementId}></div>
}

const DepthPopout: FC<Props> = ({ showCloseAll, securityId, isAdmin }) => {
  const lastHeightRef = useRef<number | undefined>()
  const { ref, height } = useResizeObserver<HTMLDivElement>()
  const { fin, createOpenfinWindow, manageWindows } = useOpenFin()
  const [externalWindow, setExternalWindow] = useState<any>(null)
  const [containerElement, setContainerElement] = useState<HTMLElement | null>(
    null
  )
  const securityOrderData = useSelector(getSecurityOrderDataById)(securityId)
  const securityStaticData = useSelector(getSecurityStaticDataById)(securityId)

  const val = useRef()

  useEffect(() => {
    val.current = externalWindow
  }, [externalWindow])

  useEffect(() => {
    if (externalWindow && height && height !== lastHeightRef.current) {
      externalWindow.resizeTo(545, height)
      lastHeightRef.current = height
    }
  }, [height])

  const onUnload = () => {
    if (val.current) {
      const w = val.current as any
      w.hide()
    }
  }

  useEffect(() => {
    window.addEventListener('beforeunload', onUnload)
    return () => {
      if (val.current) {
        const w = val.current as any
        w.hide()
      }
    }
  }, [])

  useEffect(() => {
    if (fin && securityStaticData) {
      const app = fin.desktop.Application.getCurrent()
      app.getChildWindows((children: any) => {
        if (
          !children.some((child: any) =>
            child.name.startsWith(`Depth/${securityId}`)
          )
        ) {
          fin.desktop.System.getMousePosition((mousePosition: any) => {
            renderWindow(mousePosition.left, mousePosition.top)
          })
        }
      })
    } else {
      manageWindows(`Depth/${securityId}`, 'hide')
    }
  }, [securityId, securityOrderData, securityStaticData])

  const renderWindow = (left: number, top: number) => {
    if (externalWindow) {
      externalWindow.setAsForeground()
    } else {
      createOpenfinWindow(`Depth/${securityId}`, left, top, false, true)
        .then((extWindow) => {
          if (extWindow) {
            const webWindow = extWindow.getWebWindow()
            setContainerElement(
              webWindow.document.getElementById(popupElementId)
            )

            copyAppStyles(webWindow)
            extWindow.on('close-requested', () => {
              extWindow.hide()
            })
            extWindow.restore()
          }
          setExternalWindow(extWindow)

          return () => {
            if (extWindow) {
              extWindow.hide()
            }
          }
        })
        /* eslint-disable no-console */
        .catch((err) => console.log(err))
    }
  }
  // Render this component's children into the root element of the popout window
  // return ReactDOM.createPortal(props.children, containerElement)

  return (
    containerElement &&
    externalWindow &&
    securityStaticData &&
    ReactDOM.createPortal(
      <div className={styles.windowContainer} ref={ref}>
        <PopoutDepthOfMarket
          security={securityStaticData}
          showCloseAll={showCloseAll}
          gridIndex={0}
          isAdmin={isAdmin}
          key={securityId}
        />
      </div>,
      containerElement
    )
  )
}

export default DepthPopout
